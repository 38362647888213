.slick-next,
.slick-prev {
   font-size: 0;
   line-height: 0;
   top: 110%;
   width: 50px;
   height: 50px;
   -webkit-transform: translate(0, -50%);
   -ms-transform: translate(0, -50%);
   transform: translate(0, -50%);
   cursor: pointer;
   color: transparent;
   border: none;
   border: 1px solid #9F9B9B;
   border-radius: 50%;
   outline: 0;
   background: 0 0 !important;
}

.slick-prev {
   left: 90% !important;
}

.slick-next {
   right: 10px;
}

.sc-UpCWa.fIuiET>.slick-slider>.slick-prev,
.sc-UpCWa.fIuiET>.slick-slider>.slick-next {
   top: -70px !important;
}

.sc-UpCWa.fIuiET>.slick-slider>.slick-prev {
   left: 80% !important;
}

.sc-UpCWa.fIuiET>.slick-slider>.slick-next {
   right: 10px;
}

.slick-next:before,
.slick-prev:before {
   font-size: 20px;
   line-height: 1;
   opacity: .75;
   color: #fff;
   background: none;
}

.slick-prev::before {
   font-family: FontAwesome;
   content: "\f060";
   font-weight: 300;
}

.slick-next::before {
   font-family: FontAwesome;
   content: "\f061";
   font-weight: 300;
}

@media only screen and (max-width: 731px) {

   .slick-next,
   .slick-prev {
      top: -12%;
   }

   .slick-prev {
      left: 80% !important;
   }

   .slick-next {
      right: 20px !important;
   }
}

.slick-slider {
   height: 450px !important;
}

.slick-list {
   height: 100% !important;
}

@media only screen and (max-width: 768px) {
   .slick-prev {
      left: 83% !important;
   }
}

@media only screen and (max-width: 720px) {
   .slick-prev {
      left: 79% !important;
   }
}

@media only screen and (max-width: 656px) {
   .slick-prev {
      left: 79% !important;
   }
}

@media only screen and (max-width: 588px) {
   .slick-prev {
      left: 80% !important;
   }

   .slick-next:before,
   .slick-prev:before {
      font-size: 16px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      background: none;
   }

   .slick-next,
   .slick-prev {
      width: 40px;
      height: 40px;
   }
}

@media only screen and (max-width: 522px) {
   .slick-prev {
      left: 77% !important;
   }

   .slick-next:before,
   .slick-prev:before {
      font-size: 16px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      background: none;
   }

   .slick-next,
   .slick-prev {
      width: 40px;
      height: 40px;
   }

   .sc-kImNAt.ihkvVL> .slick-slider> .slick-prev,
   .sc-kImNAt.ihkvVL> .slick-slider> .slick-next {
      top: -10% !important;
   }
}

@media only screen and (max-width: 522px) {
   .slick-prev {
      left: 75% !important;
   }

   .slick-next:before,
   .slick-prev:before {
      font-size: 16px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      background: none;
   }

   .slick-next,
   .slick-prev {
      width: 40px;
      height: 40px;
   }

   .sc-kImNAt.ihkvVL> .slick-slider> .slick-prev,
   .sc-kImNAt.ihkvVL> .slick-slider> .slick-next {
      top: -9% !important;
   }
   
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-prev,
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-next {
      top: -10% !important;
   }

   
}

@media only screen and (max-width: 425px) {
   .slick-prev {
      left: 70% !important;
   }
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-prev,
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-next {
      top: -10% !important;
   }

   .sc-bTTELM.kalBOK > .slick-slider{
      height: 310px !important;
   }

   
   .sc-hOzowv.eIxFzc> .slick-slider> .slick-prev,
   .sc-hOzowv.eIxFzc> .slick-slider> .slick-next {
      top: -9% !important;
   }
}

@media only screen and (max-width: 375px) {
   .slick-prev {
      left: 71% !important;
   }

   .slick-next,
   .slick-prev {
      width: 35px;
      height: 35px;
   }

   .slick-next,
   .slick-prev {
      top: -6%;
   }

   .slick-next:before,
   .slick-prev:before {
      font-size: 16px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      background: none;
   }
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-prev,
   .sc-cUEOzv.eUCrQZ> .slick-slider> .slick-next {
      top: -7% !important;
   }
   .sc-kImNAt.ihkvVL> .slick-slider> .slick-prev, .sc-kImNAt.ihkvVL> .slick-slider> .slick-next {
      top: -7% !important;
  }
  
  
}

@media only screen and (max-width: 320px) {
   .slick-prev {
      left: 67% !important;
   }
}